import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { getFirestore, addDoc, collection } from "firebase/firestore";
import swal from "sweetalert";
import { ProgressBar } from "react-bootstrap";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
const ApplyJob = () => {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [clgName, setClgName] = useState("");
  const [dept, setDept] = useState("");
  const [refCode, setrefCode] = useState("");
  const [experience, setexperience] = useState("");
  const [progress, setProgress] = useState(0);
  //const [resume_url, setResumeUrl] = useState("");
  const db = getFirestore();
  const [resume, setResume] = useState("");
  let navigate = useNavigate();

  // console.table(property)
  const role = localStorage.getItem("id");
  const role1 = localStorage.getItem("role");

  if (role == localStorage.getItem("id")) {
  } else {
    navigate(`/`);
  }

  const PostJob = (e) => {
    e.preventDefault();
    const setData = async () => {};
    setData();
  };

  const ApplyJob = (e) => {
    uploadFiles(resume);
    e.preventDefault();
  };

  const storage = getStorage();
  //const [temp, setTemp] = useState("");

  const uploadFiles = (resume) => {
    //
    if (!resume) return;
    const sotrageRef = ref(storage, `files/${resume.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, resume);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.table(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //console.log("File available at", downloadURL);
          console.table("downloadURL", typeof downloadURL);

          const ref = collection(db, "AppliedJob");
          addDoc(ref, {
            AppliedFor: role1,
            Email: email,
            Full_Name: fullName,
            Mobile_Number: number,
            Address: address,
            College_Name: clgName,
            Department: dept,
            Resume: downloadURL,
            Refferal_Code: refCode,
            Experience: experience,
          })
            .then(() => {
              swal({
                title: "Apply Job Successfully",
                icon: "success",
                button: "Okay",
              });
              localStorage.clear();
              setProgress(0);
              setAddress("");
              setEmail("");
              setClgName("");
              setDept("");
              setFullName("");
              setNumber("");
              setResume(null);
              setrefCode("");
              setexperience("");
              navigate("/");
            })
            .catch((err) => {
              //console.log(err);
              swal({
                title: "Failed to  apply job",
                icon: "warning",
                button: "Okay",
              });
            });
        });
      }
    );
  };

  return (
    <>
      <div className="container ">
        <div className="row gx-5">
          <div className="col ">
            <p className="h4 pl-4">You're Applying for {role1}</p>

            <form className="center loginform" onSubmit={ApplyJob}>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="floatingInput">Email</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={fullName}
                  required
                  onChange={(e) => setFullName(e.target.value)}
                />
                <label htmlFor="floatingInput">Full Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={number}
                  required
                  onChange={(e) => setNumber(e.target.value)}
                />
                <label htmlFor="floatingInput">Mobile Number</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={address}
                  required
                  onChange={(e) => setAddress(e.target.value)}
                />
                <label htmlFor="floatingInput">Address</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={clgName}
                  required
                  onChange={(e) => setClgName(e.target.value)}
                />
                <label htmlFor="floatingInput">College Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={dept}
                  required
                  onChange={(e) => setDept(e.target.value)}
                />
                <label htmlFor="floatingInput">
                  Year of Study and Department {}
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={refCode}
                  onChange={(e) => setrefCode(e.target.value)}
                />
                <div className="mb-3">
                  <label htmlFor="B_file" className="form-label mt-2">
                    Upload Resume
                  </label>
                  <input
                    className="form-control form-control1"
                    type="file"
                    onChange={(e) => {
                      setResume(e.target.files[0]);
                    }}
                    required
                  />
                  <br />
                  <p>Uploading done {progress}%</p>
                  <ProgressBar animated now={progress} />
                </div>
                <label htmlFor="floatingInput">Refferal code</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={experience}
                  style={{ height: 100 }}
                  onChange={(e) => setexperience(e.target.value)}
                />
                <label htmlFor="floatingInput">Experience, if any?</label>
              </div>

              <button type="submit" className="btn m-0 admitbtn">
                Apply
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ApplyJob;
