// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDcbH3w-MsSvKjUCOtFDj1MU2F5h9mk5xA",
  authDomain: "career-cwi.firebaseapp.com",
  projectId: "career-cwi",
  storageBucket: "career-cwi.appspot.com",
  messagingSenderId: "538388556630",
  appId: "1:538388556630:web:36aacc06d100ce94f2c4f7",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging();
