import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Link,useNavigate } from "react-router-dom";
import swal from 'sweetalert';

const Dashboard = () =>{
    let navigate = useNavigate();

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            
        } else {
            swal({
                title: "Buddy,login first",
                text: "Please login",
                icon: "warning",
                button: "Okay",
              });
            navigate(`/`);
        }
      });

      const handleSignOut = ()=>{
        signOut(auth).then(() => {
            swal({
                title: "Sign Out Succcesfully",
                icon: "success",
                button: "Okay",
              });
              navigate(`/`);
        }).catch((error) => {
  
        });
      }
      

    return(
        <>
            
            <div className="container ">
                <div className="col-sm text-center">
                    <i className="fa fa-home h3" aria-hidden="true"> Admin Panel</i>
                    <p className="h3"></p>
                    <button onClick={handleSignOut} className="btn admitbtn">Sign Out</button>
                </div>

                <div className="row gy-5">
                    <div className="col-sm">    
                        <div className="card  text-center">
                            <div className="card-body admin-card">
                                <p className="h4 text-dark"> <i className="fa fa-comment" aria-hidden="true"></i> Add Job</p>
                                <Link to="/dashboard/add-job" className="btn btn-secondary">Go</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><hr/><br/>
                <div className="row gy-5">
                    <div className="col-sm">    
                        <div className="card text-center">
                            <div className="card-body admin-card ">
                                <p className="h4 text-dark"> <i className="fa fa-database" aria-hidden="true"></i> View Data</p>
                                <Link to="/dashboard/view" className="btn btn-secondary">Go</Link>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )

}
export default Dashboard;