import {
  doc,
  query,
  getFirestore,
  collection,
  where,
  getDoc,
  documentId,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const View = () => {
  const db = getFirestore();

  // const [profiles, setProfiles] = useState([]);

  const [heading, setheading] = useState("");
  const [jd, setJd] = useState("");
  const [property, setProperty] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState("");
  const [no_of_Opening, setNoOfOpening] = useState("");
  const [stippend, setStippend] = useState("");
  //const ref1 = collection(db, "jobs");

  useEffect(() => {
    const id = localStorage.getItem("id");
    const q = query(collection(db, "jobs"), where(documentId(), "==", id));

    const getData = async () => {
      const data = await getDoc(doc(db, "jobs", id));
      // console.log(data)
      // localStorage.setItem("id", user.id);

      if (data.exists()) {
        //console.log(data.data().Job_Heading)
        setProperty(data.data().Job_Type);
        setheading(data.data().Job_Heading);
        setJd(data.data().Job_Description);
        setLocation(data.data().Job_Location);
        setStartDate(data.data().Job_StartDate);
        setEndDate(data.data().ApplyBy);
        setNoOfOpening(data.data().No_Of_Openings);
        setDuration(data.data().Duration);
        setStippend(data.data().Stipend);
      } else {
        console.log("No such document");
      }
    };

    getData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="col m-4">
            <div className="card">
              <div className="card-body bg-dark">
                <h5 className="card-title h2">{heading}</h5>
                <div className="alert alert-secondary" role="alert">
                  {property}
                </div>
                <p>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                  {location}
                </p>
                <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
                  <div className="col">
                    <i className="fa fa-play-circle" aria-hidden="true"></i>{" "}
                    Start Date: {startDate}
                  </div>
                  <div className="col">
                    <i
                      className="fa fa-calendar-minus-o"
                      aria-hidden="true"
                    ></i>{" "}
                    Duration: {duration}
                  </div>
                  <div className="col">
                    <i className="fa fa-money" aria-hidden="true"></i> Stipend:{" "}
                    {stippend}
                  </div>
                  <div className="col">
                    <i className="fa fa-hourglass-end" aria-hidden="true"></i>{" "}
                    Apply By: {endDate}
                  </div>
                </div>
                <p className="card-text mt-4">
                  Job Description:
                  <span>{jd}</span>
                </p>
                <p className="card-text">No of Openings: {no_of_Opening}</p>

                <Link to="/view/apply" className="btn btn-primary">
                  Apply
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default View;
