import React, {Suspense} from "react";
import Header from "./Components/header.component";
import Hiring from "./Components/hiring.component";
import LoadingComponent from "./Components/loadingComponent.component";
import Product from "./Components/product.component";
const About =  React.lazy(() => import("./Components/about.component"));
const Accordion =  React.lazy(() => import("./Components/accordion.component"));
const Footer =  React.lazy(() => import("./Components/footer.component"));
const Location =  React.lazy(() => import("./Components/location.component"));
const Services =  React.lazy(() => import("./Components/services.component"));
const Video =  React.lazy(() => import("./Components/Video.component"));

function Home() {
  return (
    <div className="App">
      <Suspense fallback={<LoadingComponent/>}>
        <Header/>
        <Services/>
        <Hiring/>
        <Product/>
        <Video/>
        <Accordion/>
        <About/>
        <Location/>
        <Footer/>
      </Suspense>
    </div>
  );
}

export default Home;