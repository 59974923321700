import React, {Suspense} from "react";
import LoadingComponent from "./Components/loadingComponent.component";
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Home';
import Admit from "./Admin/admit.admin";
import Dashboard from "./Admin/dashboard.admin";
import AddJob from "./Admin/addjob.admin";
//import Page from "./Admin/page.admin";
import ApplyJob from "./Components/apply";
import View from "./Components/view.component";
import ViewData from "./Admin/viewdata.admin";
import ScrollTop from "./Components/ScrollTop";
import PageNotFound from "./Components/404";
const Navbar =  React.lazy(() => import("./Components/navbar.component"));
// const Footer =  React.lazy(() => import("./Components/footer.component"));
function App() {
  return (
    <div className="App">
      <Router>
      <Suspense fallback={<LoadingComponent/>}>
        <Navbar/>
        <ScrollTop>
      <Routes>
        <Route path="/" exact element={<Home/>}/>
          <Route path="/admin"  element={<Admit/>}/>
          <Route path="/dashboard" exact element={<Dashboard/>} />
          <Route path="/dashboard/add-job" exact element={<AddJob/>} />
          {/* <Route path="/view" exact element={<Page/>} /> */}
          <Route path="*"  element={<PageNotFound/>} />
          <Route path="/view/apply" exact element={<ApplyJob/>} />
          <Route path="/view" exact element={<View/>} />
          <Route path="/dashboard/view" exact element={<ViewData/>} />
      </Routes>
      </ScrollTop>

        {/* <Footer/> */}
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
