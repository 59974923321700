import main from "../Animations/resume.json"
import Lottie from "lottie-react";

const Header = () => {

    return( <div className="container mb-4 mt-0">
        <div className="row">
            <div className="col align-middle v-center ">

            <p className="h1">looking for career growth opportunities ?</p>
            <p className="card-subtitle" style={{
                color: '#5DA3FA'
            }}>"We are a small EdTech IT firm, That provides Software Services Other than that We are the latest and fastest in the Diploma Engineering community for providing training."</p>
            <button className="explore-btn">Explore</button>
                
            </div>
        <div className="col-sm">
                <Lottie loop={true} animationData={main} />
            </div>
        </div>
    </div>)
}
export default Header;