import CheckUser from "./check.admin";
import { useState, useEffect } from "react";
import {
  doc,
  query,
  getFirestore,
  addDoc,
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
  where,
  getDoc,
  documentId,
} from "firebase/firestore";
import swal from "sweetalert";
import Swal from "sweetalert2"
const AddJob = () => {
  const [heading, setheading] = useState("");
  const [jd, setJd] = useState("");
  const [property, setProperty] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState("");
  const [no_of_Opening, setNoOfOpening] = useState("");
  const [stippend, setStippend] = useState("");

  const db = getFirestore();

  // console.table(property)

  const [profiles, setProfiles] = useState([]);
  //const [data_id, setData_id] = useState([]);

  const ref1 = collection(db, "jobs");

  useEffect(() => {
    const getData = async () => {
      const data = await getDocs(ref1);
      console.table(data);
      setProfiles(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    };

    getData();
  }, []);

  const AddBox = (e) => {
    e.preventDefault();
    const setData = async () => {
      const ref = collection(db, "jobs");
      addDoc(ref, {
        Job_Type: property,
        Job_Heading: heading,
        Job_Location: location,
        Job_StartDate: startDate,
        Stipend: stippend,
        Duration: duration,
        ApplyBy: endDate,
        No_Of_Openings: no_of_Opening,
        Job_Description: jd,
      })
        .then(() => {
          Swal.fire(
            {title:'Job Added Successfull!',
            icon:'success',
            confirmButtonColor: '#5ae4a7', allowOutsideClick: false,
            allowEscapeKey: false,}
          ).then((result) => {
            if (result.isConfirmed) {
              window.location.reload(true);
            }
          })
          setProperty("");
          setheading("");
          setJd("");
          setLocation("");
          setStartDate("");
          setEndDate("");
          setNoOfOpening("");
          setDuration("");
          setStippend("");
        })
        .catch((err) => {
          swal({
            title: "Failed to  add job",
            icon: "warning",
            button: "Okay",
          });
        });
    };

    setData();
  };
  return (
    <>
      <div className="container ">
        <div className="row gx-5">
          <div className="col text-center">
            <p className="h4 pl-4">Add Job</p>
            <CheckUser />
            <form className="center loginform">
              <div className="form-floating mb-3">
                <select
                  className="form-select form-control bg-secondary"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  value={property}
                  onChange={(e) => setProperty(e.target.value)}
                >
                  <option selected>Open this select property</option>
                  <option value="Internship">Internship</option>
                  <option value="Full Time Job">Full Time Job</option>
                  <option value="Part Time Job">Part Time Job</option>
                </select>
                <label htmlFor="floatingSelect">Job Type</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={heading}
                  onChange={(e) => setheading(e.target.value)}
                />
                <label htmlFor="floatingInput">Job Title</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label htmlFor="floatingInput">Location</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <label htmlFor="floatingInput">Start Date</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={stippend}
                  onChange={(e) => setStippend(e.target.value)}
                />
                <label htmlFor="floatingInput">Stipend/Salary</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
                <label htmlFor="floatingInput">Duration</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="date"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <label htmlFor="floatingInput">Apply By</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="name"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  placeholder="abc"
                  value={no_of_Opening}
                  onChange={(e) => setNoOfOpening(e.target.value)}
                />
                <label htmlFor="floatingInput">No of Openings</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  type="text"
                  className="form-control bg-secondary"
                  id="floatingInput"
                  style={{ height: 100 }}
                  placeholder="abc"
                  value={jd}
                  onChange={(e) => setJd(e.target.value)}
                />
                <label htmlFor="floatingInput">Job Description</label>
              </div>
              <button
                type="button"
                class
                className="btn admitbtn"
                onClick={AddBox}
              >
                Post
              </button>
              <button
                type="button"
                className="btn admitbtn"
                onClick={() => {
                  var x = localStorage.getItem("id");
                  const setData = async () => {
                    const washingtonRef = doc(db, "jobs", x);
                    updateDoc(washingtonRef, {
                      Job_Type: property,
                      Job_Heading: heading,
                      Job_Location: location,
                      Job_StartDate: startDate,
                      Stipend: stippend,
                      Duration: duration,
                      ApplyBy: endDate,
                      No_Of_Openings: no_of_Opening,
                      Job_Description: jd,
                    })
                      .then(() => {
                        Swal.fire(
                          {title:'Job Updated Successfull!',
                          icon:'success',
                          confirmButtonColor: '#5ae4a7', allowOutsideClick: false,
                          allowEscapeKey: false,}
                        ).then((result) => {
                          if (result.isConfirmed) {
                            window.location.reload(true);
                          }
                        })
                        //  setData();
                        setProperty("");
                        setheading("");
                        setJd("");
                        setLocation("");
                        setStartDate("");
                        setEndDate("");
                        setNoOfOpening("");
                        setDuration("");
                        setStippend("");
                      })
                      .catch((err) => {
                        swal({
                          title: "Job doest not exist",
                          icon: "warning",
                          button: "Okay",
                        });
                      });
                  };
                  setData();
                }}
              >
                Update
              </button>
            </form>
          </div>
          <div className="col">
            <p className="h4 pl-4 text-center mb-4">Total Posted Jobs</p>
            <div className="row g-2">
              {profiles.map((user) => {
                return (
                  <div>
                    {" "}
                    <div className="col">
                      <div className="card">
                        <div className="card-body cbody bg-dark">
                          <h5 className="card-title">
                            {user.Job_Heading}

                            <span>
                              {/* <p onChange={(e)=> setComId(user.id)}></p> */}
                              <div
                                class="btn-group float-end"
                                role="group"
                                aria-label="Basic outlined example"
                              >
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={() => {
                                    const q = query(
                                      collection(db, "jobs"),
                                      where(documentId(), "==", user.id)
                                    );

                                    const getData = async () => {
                                      const data = await getDoc(
                                        doc(db, "jobs", user.id)
                                      );

                                      localStorage.setItem("id", user.id);

                                      if (data.exists()) {
                                        setProperty(data.data().Job_Type);
                                        setheading(data.data().Job_Heading);
                                        setJd(data.data().Job_Description);
                                        setLocation(data.data().Job_Location);
                                        setStartDate(data.data().Job_StartDate);
                                        setEndDate(data.data().ApplyBy);
                                        setNoOfOpening(
                                          data.data().No_Of_Openings
                                        );
                                        setDuration(data.data().Duration);
                                        setStippend(data.data().Stipend);
                                      } else {
                                        console.log("No such document");
                                      }
                                    };

                                    getData();
                                  }}
                                >
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={() => {
                                    const setData = async () => {
                                      const washingtonRef = doc(
                                        db,
                                        "jobs",
                                        user.id
                                      );
                                      deleteDoc(washingtonRef, {})
                                        .then(() => {
                                          Swal.fire(
                                            {title:'Deleted Successfull!',
                                            icon:'success',
                                            confirmButtonColor: '#5ae4a7', allowOutsideClick: false,
                                            allowEscapeKey: false,}
                                          ).then((result) => {
                                            if (result.isConfirmed) {
                                              window.location.reload(true);
                                            }
                                          })
                                        })
                                        .catch((err) => {
                                          swal({
                                            title: "Failed to Delete",
                                            icon: "warning",
                                            button: "Okay",
                                          });
                                        });
                                    };
                                    setData();
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddJob;
