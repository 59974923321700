import {
  collection,
  getFirestore,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";

const ViewData = () => {
  const db = getFirestore();

  const [profiles, setProfiles] = useState([]);

  let navigate = useNavigate();

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
    } else {
      swal({
        title: "Buddy,login first",
        text: "Please login",
        icon: "warning",
        button: "Okay",
      });
      navigate(`/`);
    }
  });
  const ref1 = collection(db, "AppliedJob");

  useEffect(() => {
    const getData = async () => {
      const data = await getDocs(ref1);
      console.table(data);
      setProfiles(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    };

    getData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row ">
          {profiles.map((user) => {
            return (
              <div>
                {" "}
                <div className="col m-4">
                  <div className="card">
                    <div className="card-body bg-dark">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title h4">
                            Applied For: {user.AppliedFor}
                          </h5>
                        </div>
                        <div className="col-2">
                          <button
                            type="button"
                            className="btn btn-danger float-right"
                            onClick={() => {
                              const setData = async () => {
                                const washingtonRef = doc(
                                  db,
                                  "AppliedJob",
                                  user.id
                                );
                                deleteDoc(washingtonRef, {})
                                  .then(() => {
                                    Swal.fire({
                                      title: "Application Deleted Successfull!",
                                      icon: "success",
                                      confirmButtonColor: "#5ae4a7",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        window.location.reload(true);
                                      }
                                    });
                                  })
                                  .catch((err) => {
                                    swal({
                                      title: "Failed to Delete",
                                      icon: "warning",
                                      button: "Okay",
                                    });
                                  });
                              };
                              setData();
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>

                      <p className="h5">Full Name: {user.Full_Name}</p>
                      <p className="h5">Email: {user.Email}</p>
                      <p className="h5">Mobile No: {user.Mobile_Number}</p>
                      <p className="h5">Address: {user.Address}</p>
                      <p className="h5">College Nmae: {user.College_Name}</p>
                      <p className="h5">Department: {user.Department}</p>
                      <a href={user.Resume} className="btn btn-secondary">
                        Download Resume
                      </a>
                      <p className="h5">Refferal Code: {user.Refferal_Code}</p>
                      <p className="h5">Experience: {user.Experience}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default ViewData;
