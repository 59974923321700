import Lottie from "lottie-react";
import one from "../Animations/1.json";
import two from "../Animations/2.json";
import three from "../Animations/3.json";

import "aos/dist/aos.css";
const Hiring = () => {
  return (
    <>
      <p className=" text-center display-6">Hiring Process</p>
      <div
        data-aos="flip-up"
        className="row  d-flex justify-content-center row-cols-lg-4 g-2 g-lg-3 "
      >
        <div className="col-sm">
          <Lottie loop={true} animationData={one} />
        </div>
        <div className="col-sm">
          <Lottie loop={true} animationData={two} />
        </div>
        <div className="col-sm">
          <Lottie loop={true} animationData={three} />
        </div>
      </div>
    </>
  );
};
export default Hiring;
